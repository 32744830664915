



































import { Component, Vue, Watch } from 'vue-property-decorator'
import { vxm } from '@/store'
import { oc } from 'ts-optchain'
import SitesList from '@/components/authentication/SitesList.vue'

@Component({
  components: { SitesList },
  layout: 'unAuthenticated',
})
export default class ChooseSite extends Vue {
  confirmDialog = false
  siteToLogout = null

  get loggedInSites() {
    return Object.values(vxm.user.tokens).map(({ payload }) => ({
      id: payload.aud,
      name: payload.siteName,
      loggedInUser: payload.name,
    }))
  }

  get preferredSite() {
    return oc(vxm.user.settings).global.preferredSite(null)
  }

  get confirmMessage() {
    if (Array.isArray(this.siteToLogout)) {
      return this.$t('authentication.confirmLogoutAll')
    }
    return this.$t('authentication.confirmLogout', this.siteToLogout)
  }

  generateUrl(site) {
    return '/' + site.id + (this.$route.query.redirect || '/')
  }

  setPreferredSite(siteId) {
    vxm.user.setUserSettings({
      settings: {
        preferredSite: siteId,
      },
      userIdentifier: 'global',
    })
  }

  promptLogout(site) {
    this.siteToLogout = site
    this.confirmDialog = true
  }

  preformLogout() {
    this.confirmDialog = false
    if (Array.isArray(this.siteToLogout)) {
      vxm.user.clearTokens()
    } else {
      const newTokens = { ...vxm.user.tokens }
      delete newTokens[this.siteToLogout.id]
      vxm.user.setTokens(Object.values(newTokens).map(({ token }) => token))
    }
  }

  @Watch('loggedInSites', { immediate: true })
  onLoggedInSitesChanged(sites) {
    if (sites.length === 0) {
      this.$router.push({
        name: 'NoSite/Login',
        query: { redirect: this.$route.query.redirect || '' },
      })
    } else if (sites.length === 1) {
      this.$router.push(this.generateUrl(sites[0]))
    }
  }
}
