






























import { Component, Prop, Vue } from 'vue-property-decorator'

export type Site = {
  id: string
  name: string
  loggedInUser: boolean | string
  [key: string]: unknown
}

@Component
export default class SitesList extends Vue {
  @Prop({ required: true }) sites: Site[]
  @Prop({ default: (_entry) => undefined }) generateUrl: (entry) => boolean | string
}
